import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  statistics: icon('ic_statistics'),
  suggestions: icon('ic_suggestions'),
  previews: icon('ic_previews'),
  account: icon('ic_account'),
  help: icon('ic_help'),
  privacy: icon('ic_privacy'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const data = useMemo(
    () => [
      // ----------------------------------------------------------------------
      {
        subheader: '',
        items: [
          { title: 'Statistics', path: paths.dashboard.root, icon: ICONS.statistics },
          {
            title: 'Suggestion History',
            path: paths.dashboard.suggestions,
            icon: ICONS.suggestions,
          },
          {
            title: 'Previews',
            path: paths.dashboard.previews,
            icon: ICONS.previews,
          },
          {
            title: 'Account',
            path: paths.dashboard.account,
            icon: ICONS.account,
          },
          {
            title: 'Help',
            path: paths.dashboard.help,
            icon: ICONS.help,
          },
          {
            title: 'Privacy Policy',
            path: paths.dashboard.privacy,
            icon: ICONS.privacy,
          },
        ],
      },
    ],
    []
  );

  return data;
}
